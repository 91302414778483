.collapse-faq {
  position: relative;
  background-color: transparent;
  border: 0;
  :global {
    .ant-collapse-header {
      border-radius: 10px 10px 0 0 !important;
      padding: 15px 16px;
    }
    .ant-collapse-item {
      background-color: #fff;
      margin-bottom: 20px;
      border: 0;
      border-radius: 10px !important;
    }
    .ant-collapse-content {
      border-radius: 0 0 10px 10px !important;
    }
    .ant-collapse-content-box{
      padding: 25px;
    }
  }
  .tolbar {
    display: flex;
    align-items: center;
    .delete,
    .edit {
      height: 25px;
      background-color: #fff;
      width: 25px;
      cursor: pointer;
      font-size: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0px 2px;
      border-radius: 2px;
    }
    .delete {
      color: #ff0000;
    }
    .edit {
      color: #48dc1b;
    }
  }
}

.faq-single {
  label {
    display: block;
    background-color: rgba(143, 143, 143, 0.28);
    border-radius: 10px;
    min-height: 300px;
    padding: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .faq-single-image {
      img {
        max-height: 300px;
      }
    }
  }
}

.card-faq-single-image {
  img {
    width: 100%;
  }
}
